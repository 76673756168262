.custom-grid-container {
  border: 1px solid #e0e0e0;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;

  .ag-header {
    font-weight: 500;
    font-size: 12px;
    color: #000000;
    background-color: #F8FAFE;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
  }

  .ag-body-viewport {
    -ms-overflow-style: none;
    scrollbar-width: none;
    &::-webkit-scrollbar {
      display: none;
    }
  }

  .ag-body {
    overflow-y: auto !important;
    overflow-x: auto !important;
  }
}

.ag-body-horizontal-scroll-viewport {
  -ms-overflow-style: none;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }
}

.ag-horizontal-left-spacer {
  -ms-overflow-style: none;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }
}

.ag-row:hover {
  background-color: #e3f2fd;
}

 .ag-cell {
  border-right: 1px solid #ddd !important; 
}

.ag-header-cell {
  border-right: 1px solid #ddd !important; 
}
